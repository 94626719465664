/*
 * @Author: long
 * @Date: 2022-03-14 16:09:39
 * @LastEditTime: 2023-02-10 11:03:32
 * @LastEditors: long
 * @Description:
 * @FilePath: /website/Users/long/Documents/workspace/portal/src/main.js
 *
 */
import "core-js/stable";
import Vue from "vue";
import EventProxy from "vue-event-proxy";
import ElementUI, { Loading } from "element-ui";
import directive from "components/directive";
import "default-passive-events";
import FeAdmin from "fe-admin-component";
import "./permission";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/public/index.scss";
Vue.use(EventProxy);
Vue.use(ElementUI);
Vue.use(FeAdmin);
Vue.use(directive);

Vue.config.productionTip = false;
Vue.prototype.$loading = Loading;
Vue.prototype.$store = store;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
