const statistics = {
  namespaced: true,
  state: {
    searchConfig: {},
    costSearch: {},
    scanCaseCount: {},
    scanAgeCount: {},
    scanDiseaseCount: {},
    //
    averageCost: {},
    costStyle: {},
    costType: {}
  },
  mutations: {
    setValue(state, { type, val }) {
      if (type) {
        let data = Object.assign({}, val);
        state[type] = data;
      }
    },
    setArrValue(state, { type, val }) {
      if (type) {
        state[type] = val;
      }
    }
  }
};
export default statistics;
