/*
 * @Author: long
 * @Date: 2022-04-12 17:05:53
 * @LastEditTime: 2022-04-12 17:05:53
 * @LastEditors: long
 * @Description:
 * @FilePath: /website/Users/long/Documents/workspace/portal/src/utils/index.js
 *
 */
import Cookies from "js-cookie";

const TokenKey = "token";

export function getCookies() {
  return Cookies.get(TokenKey);
}

export function setCookies(token) {
  Cookies.set("SameSite", "Strict");
  return Cookies.set(TokenKey, token);
}

export function removeCookies() {
  return Cookies.remove(TokenKey);
}
