const notify = {
  namespaced: true,
  state: {
    notifyWs: "",
    notifyBox: "",
  },
  mutations: {
    setValue(state, { type, val }) {
      if (type) {
        state[type] = val;
      }
    },
  },
};

export default notify;
