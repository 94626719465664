/*
 * @Author: long
 * @Date: 2022-03-14 16:09:39
 * @LastEditTime: 2022-09-15 09:26:53
 * @LastEditors: long
 * @Description:
 * @FilePath: /website/Users/long/Documents/workspace/portal/src/store/index.js
 *
 */
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import user from "./modules/user";
import permission from "./modules/permission";
import notify from "./modules/notify";
import statistics from "./modules/statistics";

Vue.use(Vuex);
const state = {
  menuId: "0",
  isCollapse: false,
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: { user, permission, notify, statistics },
});
