/*
 * @Author: long
 * @Date: 2022-03-14 16:09:39
 * @LastEditTime: 2023-08-10 14:16:53
 * @LastEditors: long
 * @Description:
 * @FilePath: /website/Users/long/Documents/workspace/portal/src/router/constant-router.js
 *
 */
const router = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/password",

    component: () => import("@/views/Login/password"),
  },

  {
    path: "/home",

    component: () => import("@/views/Home"),
  },
  {
    path: "/applyNotice",
    component: () => import("@/views/Apply"),
  },
  {
    path: "/application",
    component: () => import("@/views/Apply/Form"),
  },

  {
    path: "*",
    component: () => import("@/views/ErrorPage"),
  },
];

export default router;
