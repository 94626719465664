<!--
 * @Author: long
 * @Date: 2023-02-10 13:56:41
 * @LastEditTime: 2023-02-21 08:47:36
 * @LastEditors: long
 * @Description: 
 * @FilePath: /website/Users/long/Documents/workspace/portal/src/App.vue
 * 
-->
<template>
  <router-view />
</template>
<script>
export default {};
</script>
